.payment-method {
  display: block;
  padding: 10px 15px;
  margin-bottom: 10px;
  border: 2px solid #ccc; 
  border-radius: 5px;
  cursor: pointer;
  background-color: white; 
  transition: all 0.3s ease;
}

.payment-method-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.payment-icons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.payment-icon {
  height: 24px; 
  width: auto; 
}

.payment-icon.uzcard {
  height: 27px; 
  width: auto; 
}

.payment-method-active {
  border-color: #007bff; 
  background-color: #ffffff;
  font-weight: bold;
  color: #007bff; 
}
