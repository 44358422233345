.loadcollapse-light {
  background-color: #f3f4f6;
}


.loadcollapse-dark {
  background-color: #363e47;
}


.view-on-map-modal-box-custom {
	min-width: 700px;
	max-height: 70vh;
	overflow-y: auto;
	top: 15%;
	position: absolute; 
}


.map-container {
  width: 100%;
  height: 400px; /* You can set the height according to your requirements */
}

.custom-map-icon {
  width: 25px;
  height: 25px;
  position: absolute;
}


.leaflet-routing-container-hide {
  display: none !important;
}

.leaflet-routing-alt-container {
  display: none !important;
}

.summary-control {
  background-color: #fff;
  padding: 10px;
  border: 1px solid #999;
  border-radius: 5px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}


