.checkbox-button {
  display: flex; /* Keeps using flex for centering */
  align-items: center; /* Centering vertically */
  justify-content: center; /* Centering horizontally */
  padding: 0 15px; /* Adjusted padding */
  background-color: #f5f5f6;
  color: black;
  border: 1px solid #d2d2d2;
  cursor: pointer;
  user-select: none;
  text-align: center;
  transition: background-color 0.3s ease;
  border-radius: 15px; /* Less rounded corners */
  white-space: nowrap; /* Prevents line breaking */
  overflow: hidden; /* Hides overflow */
  text-overflow: ellipsis; /* Add ellipsis for long text */
  min-width: 100px; /* Minimum width, adjust as needed */
  max-width: 250px; /* Maximum width, adjust as needed */
}

.checkbox-button.checked {
  background-color: #c2f2c5;
  color: #3d413e;
}
