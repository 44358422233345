/* styles.css */
.dark-mode-class {
  background-color: #1a1a1a;
  color: #fff;
}

.light-mode-class {
  background-color: #fff;
  color: #000;
}

.dark-mode-card {
  background-color: #333;
  color: #fff;
}

.light-mode-card {
  background-color: #fff;
  color: #000;
}




