.slimLayout {
  min-height: 100vh; 
}

.searches-modal-box-custom {
	min-width: 700px;
	max-height: 70vh;
	overflow-y: auto;
	top: 15%;
	position: absolute; 
}

.submit-button {
	visibility: visible !important;
	opacity: 1 !important;
	color: white !important; 
	background-color: #2463eb !important; 
}


.google-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: #ffffff;
  text-align: center;
  font-size: 14px;
  color: #333333; /* Adjust the color as needed to match the page */
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.google-button:hover {
  background-color: #f0f0f0;
}

.google-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(75, 85, 99, 0.16);
}

.google-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

