.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 23px; 
}

.input-container label {
  position: absolute;
  top: 24px;
  transform: translate(0, 14px) scale(0.9); 
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #6f81a5;
  font-size: 15px; 
  left: 9px;
}

.input-container:focus-within label,
.input-container label.filled {
  top: 17px;
  transform: translate(0, 0) scale(0.8); 
  color: #0a53e4; 
  background-color: #f5f5f7;
}

.filled {
  /* Add your styling for the filled state here */
  top: 17px;
  transform: translate(0, 0) scale(0.8); 
  color: #0a53e4; 
  background-color: #f5f5f7;
}

.input-container input {
  font-size: 18px; 
  height: 45px;
}

.select-wrap {
  top: 23px;
  padding: 0 5px;
  width: auto;
  position: relative;
}


.select-wrap label {
  font-size: 10px;
  color: #777;
  padding: 0 4px; 
  position: absolute;
  top: 0px; 
  left: 6px;
  padding: 0px;
  background-color: #f5f5f7;
  z-index: 10;
  line-height: 1; 
  white-space: nowrap;
}

.radius-select select{
  top: 8px; 
  height: 45px;;
  font-size: 13px;
  border-left: none;
  margin-left: -4px;
}

.equipment-select select{
  top: 8px; 
  height: 45px;;
  font-size: 13px;
  margin-left: auto;
}


.bg-dark-mode-color {
  background-color: #1c2127; /* Your dark mode background color */
}

.bg-light-mode-color {
  background-color: #f5f5f7; /* Your light mode background color */
}

.select.sortby-select {
	height: 10px !important; 
}
